import { createWebHistory, createRouter } from "vue-router";

const routes = [
    {
      path: '/',
      name: 'landing',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/PageDemo.vue'),
    },
    {
      path: '/demo',
      name: 'demo',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/PageDemo.vue'),
    },
    {
      path: '/maeni',
      name: 'maeni',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/PageDemoMAENI.vue'),
    },
    {
      path: '/meldogram',
      name: 'meldogram',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/PageDemoMELDOGRAM.vue'),
    },
    {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/PageAboutNew.vue'),
    },
  ];

const router = createRouter({
  history: createWebHistory(),
  routes,
});


router.afterEach(() => {
  window.scrollTo(0, 0);
});


export default router;
